
      import 'uno.css'
      import sampleImage from '/src/assets/Splendid_Fairywren_8352_portrait.jpg'

      const editor = document.getElementById('imageEditor')

      editor.sources = [sampleImage]

      const fileInput = document.getElementById('fileInput')
      fileInput.addEventListener('input', function (event) {
        const file = event.target.files?.[0]
        if (!file) return
        editor.sources = [file]
        event.target.value = null
      })

      window.onerror = (event, ...args) => {
        document.getElementById('errors').textContent = [event.toString(), args[2]?.stack]
          .filter(Boolean)
          .join('\n')
      }
    